<template>
  <div>
    <vs-popup
      fullscreen
      class="holamundo dcr-particular-popup"
      title="PARTICULARS LIST"
      :active.sync="allParticularsIDPopup"
    >
      <div v-if="allParticulars.length > 0">
        <div
          class="col-12 mb-5 py-3 border border-primary rounded"
          v-for="(dcr, dcrIndex) in allParticulars"
          :key="dcrIndex"
        >
          <div class="mb-3">
            <div class="row w-100 align-items-center">
              <div class="col-11 mx-auto">
                <div class="row">
                  <div class="col-4 mb-2">
                    <h6>Project</h6>
                    <p>{{ dcr.project_name }}</p>
                  </div>
                  <div class="col-2 mb-2">
                    <h6>Date</h6>
                    <p>{{ dcr.dcr_date }}</p>
                  </div>
                  <div class="col-2 mb-2">
                    <h6>Category</h6>
                    <p>{{ dcr.parent_category_name }}</p>
                  </div>
                  <div class="col-2 mb-2">
                    <h6>Subcategory</h6>
                    <p>{{ dcr.category_name }}</p>
                  </div>
                  <div class="col-2 mb-2">
                    <h6>Description</h6>
                    <p>{{ dcr.dcr_description }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="removeSelectedDCRAcc">
            <vs-collapse>
              <vs-collapse-item>
                <div slot="header">DCR List</div>
                <div class="pp-section" v-if="dcr.particulars">
                  <div
                    v-for="(item, dcrIndex) in dcr.particulars"
                    :key="dcrIndex"
                  >
                    <div
                      class="m-3"
                      style="border: 1px solid #ecc7c7; padding: 10px"
                    >
                      <div class="row w-100 align-items-center">
                        <div class="col-11 mx-auto">
                          <div class="row">
                            <div class="col-4 mb-2">
                              <h6>Vendor Name</h6>
                              <p>{{ item.dcr_particular_name }}</p>
                            </div>
                            <div class="col-2 mb-2">
                              <h6>Per Day</h6>
                              <p>{{ item.per_day }}</p>
                            </div>
                            <div class="col-2 mb-2">
                              <h6>Shift</h6>
                              <p>{{ item.shift }}</p>
                            </div>
                            <div class="col-2 mb-2">
                              <h6>Unit Cost</h6>
                              <p>{{ item.unit_cost }}</p>
                            </div>
                            <div class="col-2 mb-2">
                              <h6>Calculated Cost</h6>
                              <p>{{ item.calculatedCost }}</p>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-4 mb-2">
                              <h6>Extra Cost</h6>
                              <p>{{ item.extra_cost }}</p>
                            </div>
                            <div class="col-6 mb-2">
                              <h6>Reason</h6>
                              <p>{{ item.extra_cost_reason }}</p>
                            </div>
                            <div class="col-2 mb-2">
                              <h6>Final Cost</h6>
                              <p>{{ item.total_cost }}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <h6 class="text-center py-4">No Report's Available</h6>
                </div>
                <!-- ************* -->
              </vs-collapse-item>
            </vs-collapse>
          </div>
        </div>
      </div>

      <div class="my-5" v-else>
        <h6 class="text-center py-4">No DCR Available</h6>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import Vue from "vue";
import { AgGridVue } from "ag-grid-vue";
import "@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss";
import dcrService from "@/services/dcrService.js";

import moment from "moment";
export default Vue.extend({
  props: {
    DCRData: {
      type: Object,
    },
  },
  components: {
    AgGridVue,
  },
  data() {
    return {
      params: null,
      allParticularsIDPopup: false,
      particularsArray: [],
      budgetDetail: [],
      budgetList: [],
      ParticularsData: [],
      totalPages: 0,
      allParticulars: [],
      popupActive: false,
      gridApi: null,
      searchQuery: "",
      gridOptions: {},
      maxPageNumbers: 7,
      budgetLogs: [],
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
      columnDefs: [],
    };
  },

  beforeMount: function () {},
  mounted() {
    this.gridApi = this.gridOptions.api;

    eventBus.$on("IsReportParticularPopup", (e) => {
      this.allParticulars = [];
      this.$vs.loading();
      this.allParticularsIDPopup = true;
      setTimeout(() => {
        if (this.DCRData.hasOwnProperty("project_Id")) {
          this.getAllParticularsData(
            this.DCRData.project_Id,
            this.DCRData.dcr_Ids
          );
        } else {
          // this.$vs.notify({
          //   title: "Somthing Went Wrong",
          //   text: "No data found",
          //   iconPack: "feather",
          //   icon: "check_box",
          //   color: "warning",
          // });
          this.$vs.loading.close();
        }
      }, 300);
    });
  },
  beforeDestroy() {},
  watch: {},
  computed: {},
  methods: {
    getAllParticularsData(project_ID, DCR_ID) {
      dcrService
        .getAllReportParticulars(project_ID, DCR_ID)
        .then((response) => {
          const { data } = response;
          if (data["data"] && data.Status) {
            this.allParticulars = data.data;
          } else {
            this.$vs.notify({
              title: "Somthing Went Wrong",
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          }
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error!",
            text: error.Message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },
  },
});
</script>

<style></style>
