<!-- =========================================================================================
    File Name: AgGridTable.vue
    Description: Ag Grid table
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard
    Author:
    Author URL:
========================================================================================== -->
<template>
  <div>
    <!-- DPR FORM  -->
    <div class="vx-row">
      <!-- HORIZONTAL LAYOUT -->
      <div class="vx-col w-full">
        <vx-card class="form-title" title="Report">
          <form>
            <div class="vx-row">
              <!-- LEFT SIDE -->
              <div class="vx-col md:w-3/4 mx-auto">
                <div class="vx-row mb-6">
                  <div
                    class="vx-col sm:w-1/5 w-full text-right align-self-center"
                  >
                    <h6 class="mb-0">Project Name</h6>
                  </div>
                  <div class="vx-col sm:w-2/3 w-full">
                    <div>
                      <multiselect
                        v-model="form.projectSelected"
                        track-by="project_id"
                        label="project_name"
                        :options="projects"
                        @select="categoryList"
                        name="Project Name"
                        placeholder="Select Project"
                        :searchable="true"
                        :allow-empty="false"
                        open-direction="bottom"
                        deselect-label="Can't remove this value"
                      >
                        <template slot="singleLabel" slot-scope="{ option }">
                          {{ option.project_name }}
                        </template>
                      </multiselect>
                    </div>
                  </div>
                </div>

                <div class="vx-row mb-6">
                  <div
                    class="vx-col sm:w-1/5 w-full text-right align-self-center"
                  >
                    <h6 class="mb-0">DCR Date</h6>
                  </div>
                  <div class="vx-col sm:w-1/3 w-full">
                    <div class="d-flex align-items-center">
                      <div class="w-100">
                        <datepicker
                          placeholder="From Date"
                          :disabledDates="startDisabledDates"
                          @selected="onSelectDate($event)"
                          format="dd-MM-yyyy"
                          v-model="date_From"
                        ></datepicker>
                      </div>
                    </div>
                  </div>
                  <div class="vx-col sm:w-1/3 w-full">
                    <div class="d-flex align-items-center">
                      <div class="w-100">
                        <datepicker
                          placeholder="To Date"
                          :disabledDates="endDisabledDates"
                          v-model="date_to"
                          format="dd-MM-yyyy"
                          :disabled="toDateDisable"
                        ></datepicker>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="vx-row mb-6">
                  <div
                    class="vx-col sm:w-1/5 w-full text-right align-self-center"
                  >
                    <h6 class="mb-0">Category</h6>
                  </div>
                  <div class="vx-col sm:w-2/3 w-full">
                    <div class="d-flex align-items-center">
                      <div class="w-100 mr-2">
                        <multiselect
                          label="category_name"
                          class="w-full"
                          track-by="category_id"
                          @select="onSuggestSelect"
                          v-model="categoryValue"
                          name="Category Name"
                          :options="categoriesByProject"
                          :searchable="true"
                          :allow-empty="false"
                          open-direction="bottom"
                          placeholder="Select Option"
                          deselect-label="Can't remove this value"
                        >
                          <template
                            slot="singleLabel"
                            slot-scope="{ option }"
                            >{{ option.category_name }}</template
                          >
                        </multiselect>
                        <!-- :disabledDates="startDisabledDates"-->
                      </div>
                    </div>
                  </div>
                </div>
                <div class="vx-row mb-6">
                  <div
                    class="vx-col sm:w-1/5 w-full text-right align-self-center"
                  >
                    <h6 class="mb-0">Sub Category</h6>
                  </div>
                  <div class="vx-col sm:w-2/3 w-full">
                    <div class="d-flex align-items-center">
                      <div class="w-100 mr-2">
                        <multiselect
                          label="category_name"
                          class="w-full"
                          track-by="category_id"
                          v-model="subcategoryValue"
                          name="Subcategory Name"
                          :options="subcategoryList"
                          :searchable="true"
                          :allow-empty="false"
                          open-direction="bottom"
                          placeholder="Select Option"
                          deselect-label="Can't remove this value"
                        >
                          <template
                            slot="singleLabel"
                            slot-scope="{ option }"
                            >{{ option.category_name }}</template
                          >
                        </multiselect>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- SUBMIT BUTTON -->
            <div class="vx-row">
              <div class="vx-col mx-auto">
                <vs-button
                  :disabled="submitStatus"
                  type="filled"
                  @click.prevent="submitForm"
                  class="mr-3"
                  >Submit</vs-button
                >
                <vs-button
                  color="warning"
                  type="border"
                  class
                  @click.prevent="clearForm"
                  >Reset</vs-button
                >
              </div>
            </div>
          </form>
        </vx-card>
      </div>
    </div>

    <div class="vx-col w-full mt-4">
      <vx-card class="form-title" title="Report List">
        <!-- <div class="flex flex-wrap justify-end items-center">
          <div
            class="flex flex-wrap items-center justify-between ag-grid-table-actions-right"
          >
            <vs-input
              class="mb-4 md:mb-0 mr-4"
              v-model="searchQuery"
              @input="updateSearchQuery"
              placeholder="Search..."
            />
            <vx-tooltip text="Export Excel">
              <a
                :href="
                  excelurl +
                  `/v2/department/excel/download?organization_id=${organization_id}&token=${temp_token}&search=${searchQuery}`
                "
                target="_blank"
                v-if="this.archive != 'archive'"
              >
                <vs-button class="px-4">
                  <span class="d-inline-flex pr-5 text-white">Export</span>
                  <img
                    src="@/assets/images/icons/export-excel.svg"
                    class="d-inline-flex"
                    height="20"
                    width="20"
                  />
                </vs-button>
              </a>
            </vx-tooltip>

            <vx-tooltip text="Export Archive Excel">
              <a
                :href="
                  excelurl +
                  `/v2/department/excel/download?organization_id=${organization_id}&token=${temp_token}&archive=1&search=${searchQuery}`
                "
                target="_blank"
                v-if="this.archive == 'archive'"
              >
                <vs-button class="px-4">
                  <span class="d-inline-flex pr-5 text-white">Export</span>

                  <img
                    src="@/assets/images/icons/export-excel.svg"
                    class="d-inline-flex"
                    height="20"
                    width="20"
                  />
                </vs-button>
              </a>
            </vx-tooltip>
          </div>
        </div> -->
        <ag-grid-vue
          :gridOptions="gridOptions"
          class="ag-theme-material w-100 my-4 ag-grid-table"
          :columnDefs="columnDefs"
          :defaultColDef="defaultColDef"
          @rowClicked="onRowClicked($event)"
          :rowData="allReportList"
          rowSelection="rowSelection"
          :suppressRowClickSelection="true"
          :singleClickEdit="true"
          :animateRows="true"
          :floatingFilter="false"
          :paginationPageSize="paginationPageSize"
          :pagination="true"
          :suppressPaginationPanel="true"
        ></ag-grid-vue>
        <vs-pagination
          :total="totalPages"
          :max="maxPageNumbers"
          v-model="currentPage"
        />
      </vx-card>
    </div>

    <!-- ALL PARTICULARS  -->
    <div>
      <all-report-particulars :DCRData="DCRData"></all-report-particulars>
    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import { BASEURL } from "@/config/index.js";
import axios from "axios";
import CategoryService from "@/services/categoryService.js";
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import { TokenService } from "@/services/storage.service.js";
import CurrencyService from "@/services/currencyService";
import ProjectService from "@/services/projectService";
import dcrService from "@/services/dcrService.js";

import allReportParticulars from "@/views/apps/DPR/allReportParticulars.vue";

const VxTour = () => import("@/components/VxTour.vue");

export default {
  components: {
    AgGridVue,
    Datepicker,
    allReportParticulars,
  },
  data() {
    return {
      startDisabledDates: {
        // from: new Date(),
      },
      endDisabledDates: {
        // from: new Date(),
        // to: null,
      },
      toDateDisable: true,
      project_id: "",
      allParticularsID: false,
      DCR_ID: null,
      allReportList: [],
      excelurl: BASEURL(),
      submitStatus: true,
      projectSelected: [],
      projects: [],
      CurrentPage: 1,
      totalPages: 0,
      DCRData: {},
      subcategoryList: [],
      categoriesByProject: [],
      form: {
        projectSelected: "",
      },
      date_From: "",
      date_to: "",
      dcr_date: "",
      searchQuery: "",
      gridOptions: {},
      maxPageNumbers: 7,
      gridApi: null,
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
      rowSelection: null,
      categoryValue: "",
      subcategoryValue: "",
      fromDate: null,
      toDate: null,
      configFromdateTimePicker: {
        minDate: new Date(),
        maxDate: null,
      },
      configTodateTimePicker: {
        minDate: null,
      },
      columnDefs: [],
    };
  },
  watch: {
    form: {
      handler: function (newVal, oldVal) {
        let len = Object.keys(this.form).length;
        if (
          len ===
          Object.keys(newVal).filter(
            (x, index) =>
              newVal[x] &&
              newVal[x] !== undefined &&
              String(newVal[x]).trim().length > 0
          ).length
        ) {
          this.submitStatus = false;
        } else {
          this.submitStatus = true;
        }
      },
      deep: true,
    },
  },
  beforeMount() {
    this.columnDefs = [
      {
        headerName: "Budget amount",
        field: "budget_amount",
        filter: false,
        width: 200,
        suppressSizeToFit: true,
      },
      {
        headerName: "Category",
        field: "parent_category_name",
        filter: false,
        width: 250,
        suppressSizeToFit: true,
      },
      {
        headerName: "Sub Category",
        field: "category_name",
        filter: false,
        width: 250,
      },

      {
        headerName: "DCR Total Amount",
        field: "DCR_total_amount",
        filter: false,
        width: 200,
        sortable: false,
      },
      {
        headerName: "Actual",
        field: "actual_amount",
        filter: false,
        width: 200,
        sortable: false,
      },
      {
        headerName: "Variance",
        field: "variance_amount",
        filter: false,
        width: 200,
        sortable: false,
      },
      {
        headerName: "Balance",
        field: "balance_amount",
        filter: false,
        width: 200,
        sortable: false,
      },
    ];
    this.rowSelection = "single";
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 100;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        if (val) {
          // this.getDepartmentList(val);
          return val;
        } else return 1;
      },
    },
  },
  methods: {
    onSelectDate: function ($event) {
      this.endDisabledDates["to"] = new Date(moment(new Date($event)).format());
      this.date_to = "";
      this.toDateDisable = false;
    },
    onRowClicked(e) {
      this.DCR_ID = e.data.dcr_ids;
      this.DCRData = {
        dcr_Ids: e.data.dcr_ids,
        project_Id: this.project_id,
      };
      eventBus.$emit("IsReportParticularPopup");
    },

    categoryList: function (selectedOption, id) {
      this.project_id = selectedOption.project_id;
      this.getAllCategoryListByProject(this.project_id);
    },
    onSuggestSelect: function (selected) {
      this.getSubcategoryList(selected.category_id, this.project_id);
    },
    getAllCategoryListByProject: function (id) {
      CategoryService.getProjectMainCategories(id)
        .then((response) => {
          const { data } = response;
          if (data.Status) {
            this.categoriesByProject = data.data;
            console.log(data);
          }
        })
        .catch((error) => {
          console.log("error :", error);
        });
    },
    getSubcategoryList: function (ID, project_id) {
      let pId = project_id;
      CategoryService.getSubCategoriesByParentCategory(ID, pId)
        .then((response) => {
          const { data } = response;
          if (data["data"] && data.Status) {
            this.subcategoryList = data.data;
            if (this.formMouId > 0) {
              let subcategoryArray = this.subcategoryList.filter((x) => {
                return x.category_id == this.form.subcategoryValue;
              });
              this.form.subcategoryValue = subcategoryArray[0];
            }
          } else {
            this.$vs.notify({
              title: "Somthing Went Wrong",
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          }
        })
        .catch((error) => {
          this.$vs.notify({
            title: "Error!",
            text: error.Message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },

    onClickSampleDownload: function (URL) {
      window.open(URL, "_blank");
    },
    //  getAllProjects
    getProjectsList: function () {
      ProjectService.getAllProjects()
        .then((response) => {
          const { data } = response;
          if (data.Status) {
            this.projects = data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    submitForm() {
      this.$vs.loading();
      this.$validator.validateAll().then((result) => {
        if (result) {
          let payload = {
            project_id: this.form.projectSelected.project_id,
            date_From: this.date_From
              ? moment(this.date_From).format("YYYY-MM-DD")
              : "",
            date_to: this.date_to
              ? moment(this.date_to).format("YYYY-MM-DD")
              : "",
            category_id: this.subcategoryValue.category_id || "",
          };

          dcrService
            .getDCRReports(payload)
            .then((response) => {
              const { data } = response;
              if (data["data"] && data.Status) {
                this.allReportList = data.data;
              } else {
                this.$vs.notify({
                  title: "Somthing Went Wrong",
                  text: data.Message,
                  iconPack: "feather",
                  icon: "check_box",
                  color: "warning",
                });
              }
              this.$vs.loading.close();
            })
            .catch((error) => {
              this.$vs.loading.close();
              this.$vs.notify({
                title: "Error!",
                text: error.Message,
                iconPack: "feather",
                icon: "check_box",
                color: "warning",
              });
            });
        } else {
          // form have errors
          this.$vs.loading.close();
        }
      });
    },

    clearForm: function () {
      Object.keys(this.form).map((x, index) => {
        /_active/.test(x) ? (this.form[x] = "1") : (this.form[x] = "");
      });
      this.categoryValue = "";
      this.subcategoryValue = "";
      this.date_From = "";
      this.date_to = "";
      this.$validator.reset();
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
    onFromChange(selectedDates, dateStr, instance) {
      this.$set(this.configTodateTimePicker, "minDate", dateStr);
    },
    onToChange(selectedDates, dateStr, instance) {
      this.$set(this.configFromdateTimePicker, "maxDate", dateStr);
    },
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    this.gridColumnApi = this.gridOptions.columnApi;

    this.getProjectsList();
  },
};
</script>
